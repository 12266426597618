import { SignIn } from "@clerk/nextjs";
import Head from "next/head";

const SignInPage = () => (
  <>
    <Head>
      <title>Menza - Your AI data analyst</title>
      <meta
        name="description"
        content="With just one click you can connect your analytics to a super smart AI analyst. Just ask and your AI can create reports, graphs, insights and more!"
      />
    </Head>
  
    <div className="h-full w-full flex justify-center font-display items-center">
      <SignIn path="/sign-in"  signUpUrl="/sign-up" />
    </div>
  </>
);
export default SignInPage;